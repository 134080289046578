hns('I18n.translations.th', {
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": true
    },
    "percentage": {
      "format": {
        "delimiter": ",",
        "format": "%n%",
        "precision": 0
      }
    },
    "human": {
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": "ไบต์",
          "gb": "จิกะไบต์",
          "kb": "กิโลไบต์",
          "mb": "เมกะไบต์",
          "tb": "เทระไบต์"
        }
      },
      "array": {
        "inclusive": {
          "wordsConnector": " ",
          "twoWordsConnector": "และ",
          "lastWordConnector": " และ"
        },
        "exclusive": {
          "wordsConnector": ", ",
          "twoWordsConnector": " หรือ ",
          "lastWordConnector": " หรือ "
        }
      },
      "abbreviated": {
        "long": {
          "1000": {
            "other": "0 พัน"
          },
          "10000": {
            "other": "0 หมื่น"
          },
          "100000": {
            "other": "0 แสน"
          },
          "1000000": {
            "other": "0 ล้าน"
          },
          "10000000": {
            "other": "00 ล้าน"
          },
          "100000000": {
            "other": "000 ล้าน"
          },
          "1000000000": {
            "other": "0 พันล้าน"
          },
          "10000000000": {
            "other": "0 หมื่นล้าน"
          },
          "100000000000": {
            "other": "0 แสนล้าน"
          },
          "1000000000000": {
            "other": "0 ล้านล้าน"
          },
          "10000000000000": {
            "other": "00 ล้านล้าน"
          },
          "100000000000000": {
            "other": "000 ล้านล้าน"
          }
        },
        "short": {
          "1000": {
            "other": "0K"
          },
          "10000": {
            "other": "00K"
          },
          "100000": {
            "other": "000K"
          },
          "1000000": {
            "other": "0M"
          },
          "10000000": {
            "other": "00M"
          },
          "100000000": {
            "other": "000M"
          },
          "1000000000": {
            "other": "0B"
          },
          "10000000000": {
            "other": "00B"
          },
          "100000000000": {
            "other": "000B"
          },
          "1000000000000": {
            "other": "0T"
          },
          "10000000000000": {
            "other": "00T"
          },
          "100000000000000": {
            "other": "000T"
          }
        }
      }
    }
  }
});