import { css } from 'styled-components';
import { toPx } from '../../../utils/toPx';
export function remCalc(size) {
  return `${parseInt(size, 10)}px`;
}
export function emCalc(size) {
  return `${parseInt(size, 10) / parseInt('16px', 10)}em`;
}
export const toPercent = value => value == null ? undefined : `${value}%`;
export const percentToVW = width => {
  if (typeof width !== 'string') return width;
  return width.replace(/(\d+)%/g, '$1vw');
};
export const setUiTransition = (property, timing) => {
  const computedTiming = timing || '150ms';
  const computedProperty = property || 'all';
  return {
    transition: `${computedProperty} ${computedTiming} ease-out`
  };
};

/**
 * @returns a mixin that sets the appropriate width and margins to match a page template with
 * `pageLayout="max-width"`.
 */
export const setTemplateMaxWidth = () => css(["margin-left:auto;margin-right:auto;max-width:", ";"], toPx(parseInt('1280px', 10) + parseInt('48px', 10) * 2));
export const setFontSmoothing = smoothing => {
  const computedSmoothing = smoothing || 'antialiased';

  // No `css` wrapper for this mixin, due to a bug in styled-components v3
  return `
    -webkit-font-smoothing: ${computedSmoothing};
    -moz-osx-font-smoothing: ${computedSmoothing === 'antialiased' ? 'grayscale' : computedSmoothing};
  `;
};
export const setInputAppearance = theme => {
  return {
    display: 'block',
    blockSize: '40px',
    inlineSize: '100%',
    fontSize: theme.typography['body-200'].fontSize,
    lineHeight: '22px',
    textAlign: 'left',
    verticalAlign: 'middle',
    color: theme.color['text-primary-default'],
    backgroundColor: theme.color['fill-surface-raised'],
    borderBlockWidth: theme.borderWidth['100'],
    borderInlineWidth: theme.borderWidth['100'],
    borderBlockStyle: 'solid',
    borderInlineStyle: 'solid',
    borderBlockColor: theme.color['border-primary-default'],
    borderInlineColor: theme.color['border-primary-default'],
    borderStartStartRadius: theme.borderRadius['100'],
    borderStartEndRadius: theme.borderRadius['100'],
    borderEndEndRadius: theme.borderRadius['100'],
    borderEndStartRadius: theme.borderRadius['100']
  };
};