export const modernizedIconSizeMap = {
  '100': '12px',
  '200': '14px',
  '300': '16px',
  '400': '18px',
  '500': '20px',
  '600': '24px',
  '700': '28px',
  '800': '32px',
  '850': '36px',
  '900': '48px',
  '1000': '64px',
  '1100': '72px',
  '1200': '96px',
  '1300': '128px',
  inherit: '1em' // 1em = font-size of the parent element
};

/**
 * Returns a CSS Length value for the icon size.
 * @param size - The size prop value passed to the icon.
 * @returns The CSS Length value for the icon size.
 */
export const useIconSize = size => {
  // TODO: Once themed sizes are available, use the `useThemeMetadata()` hook to get the correct map

  return size ? modernizedIconSizeMap[size] : undefined;
};