import ClassicLightTheme from 'foundations-theming-base/stable/classic/light/theme';
export const LOCAL_STORAGE_THEME_KEY = 'trellis:theme';
export const LOCAL_STORAGE_THEME_OVERRIDES_KEY = 'trellis:theme-overrides';
const themeNameMapping = {
  canvas: 'classic',
  trellis: 'next'
};
const mergeThemeWithOverrides = (baseTheme, overrides) => {
  return Object.assign({}, baseTheme, {
    color: Object.assign({}, baseTheme === null || baseTheme === void 0 ? void 0 : baseTheme.color, overrides.color),
    space: Object.assign({}, baseTheme === null || baseTheme === void 0 ? void 0 : baseTheme.space, overrides.space),
    borderRadius: Object.assign({}, baseTheme === null || baseTheme === void 0 ? void 0 : baseTheme.borderRadius, overrides.borderRadius),
    borderWidth: Object.assign({}, baseTheme === null || baseTheme === void 0 ? void 0 : baseTheme.borderWidth, overrides.borderWidth),
    boxShadow: Object.assign({}, baseTheme === null || baseTheme === void 0 ? void 0 : baseTheme.boxShadow, overrides.boxShadow),
    typography: Object.assign({}, baseTheme === null || baseTheme === void 0 ? void 0 : baseTheme.typography, overrides.typography)
  });
};
const toInternalThemeName = themeName => {
  return themeNameMapping[themeName] || themeName;
};
export const getDefaultTheme = () => {
  return ClassicLightTheme;
};
export const getTheme = async (themeName, themeMode) => {
  if (themeName === 'classic') {
    if (themeMode === 'light') {
      return Promise.resolve(ClassicLightTheme);
    }
  } else if (themeName === 'pink') {
    if (themeMode === 'light') {
      return (await import('./mock-themes/pink/light/theme')).default;
    }
  } else if (themeName === 'next') {
    if (themeMode === 'light') {
      return (await import('../../themes/next/light/theme')).default;
    } else if (themeMode === 'dark') {
      return (await import('../../themes/next/dark/theme')).default;
    }
  }
  return undefined;
};
export const getLocalThemeOverride = async () => {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
    const themeOverrides = localStorage.getItem(LOCAL_STORAGE_THEME_OVERRIDES_KEY);
    if (!value && !themeOverrides) {
      return undefined;
    }
    const [themeName = 'classic', themeMode = 'light'] = (value === null || value === void 0 ? void 0 : value.split('-')) || [];
    if (themeOverrides) {
      const baseTheme = await getTheme(toInternalThemeName(themeName), themeMode);
      if (!baseTheme) {
        throw new Error('Unsupported theme and mode combination.');
      }
      const overrides = JSON.parse(themeOverrides);
      return mergeThemeWithOverrides(baseTheme, overrides);
    }
    return await getTheme(toInternalThemeName(themeName), themeMode);
  } catch (err) {
    return undefined;
  }
};
export const getThemeToProvide = async (themeName, themeMode) => {
  let theme = await getLocalThemeOverride();
  if (theme) {
    return theme;
  }
  theme = await getTheme(themeName, themeMode);
  if (theme) {
    return theme;
  }
  throw new Error('Unsupported theme and mode combination.');
};