import { useIconSize } from './useIconSize';
const legacyIconSizeMap = {
  xxs: '300',
  xs: '600',
  sm: '900',
  md: '1000',
  lg: '1100',
  xl: '1200',
  xxl: '1300',
  small: '300',
  medium: '800',
  large: '900'
};

/**
 * Returns a CSS Length value for the icon size.
 * @deprecated Use the `useIconSize` hook instead along with modernized icon size tokens.
 * @param legacySize - The legacy size prop value passed to the icon.
 * @returns The CSS Length value for the icon size.
 */
export const useLegacyIconSize = legacySize => {
  return useIconSize(legacySize ? legacyIconSizeMap[legacySize] : undefined);
};