import { createGlobalStyle } from 'styled-components';
import { classicLightCssVars } from 'foundations-theming/themes/classic/light/cssVars';
import { useThemeMetadata } from 'foundations-theming/components/ThemeProvider';
import { useEffect, useState } from 'react';
import devLogger from 'react-utils/devLogger';
const getCssVars = async (themeName, themeMode) => {
  if (themeName === 'classic') {
    if (themeMode === 'light') {
      return Promise.resolve(classicLightCssVars);
    }
  } else if (themeName === 'pink') {
    if (themeMode === 'light') {
      return (await import('./mock-themes/pink/light/cssVars')).pinkLightCssVars;
    }
  } else if (themeName === 'next') {
    if (themeMode === 'light') {
      return (await import('foundations-theming/themes/next/light/cssVars')).nextLightCssVars;
    } else if (themeMode === 'dark') {
      return (await import('foundations-theming/themes/next/dark/cssVars')).nextDarkCssVars;
    }
  }
  throw new Error('Unsupported theme and mode combination.');
};
export const GlobalCSSVariables = createGlobalStyle(() => {
  const {
    themeName,
    themeMode
  } = useThemeMetadata();
  const [cssVars, setCSSVars] = useState(classicLightCssVars);
  useEffect(() => {
    getCssVars(themeName, themeMode).then(cssVarsObject => {
      setCSSVars(cssVarsObject);
    }).catch(error => {
      devLogger.warn({
        message: `Failed to load CSS Variables with error: ${error}`,
        key: 'foundations-components:global-styles:css-vars-load-failure'
      });
    });
  }, [themeName, themeMode]);
  return {
    ':root': Object.assign({}, cssVars)
  };
});