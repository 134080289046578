import { PUBLIC_BOOK_ENDPOINT, LINK_ENDPOINT, FAILURE_ENDPOINT } from 'MeetingsLib/constants/endpoints';
import { hubletAwareApi } from 'MeetingsLib/utils/utils';
import { triggerFailureInjectionViaHeaders } from 'MeetingsLib/utils/failureInjectionUtils';
import { retryableGetClient, retryablePostClient } from './hubHttpNoAuthRetryableClient';
/**
 *  Note: Eventually we'll want to leverage meetings-client-types to type the payload/responses
 *  We'll do this in a follow up because we need to add devDeps to MeetingsLib's dependents
 *  once we add meetings-client-types as a runtimeDeps in MeetingsLib
 */

export const PUBLIC_AVAILABILITY_INFO_FETCH_RETRY_STATUS_CODES = [500, 0];
export function fetchMeetingsUser(httpClient, slug, includeInactiveLink) {
  return httpClient.get(`${PUBLIC_BOOK_ENDPOINT}/user`, {
    query: {
      includeInactiveLink,
      slug
    },
    withCredentials: false,
    api: hubletAwareApi
  });
}
export function fetchMeetingsUserWithParams(httpClient, queryParams) {
  return httpClient.get(`${PUBLIC_BOOK_ENDPOINT}/user`, {
    query: queryParams,
    withCredentials: false,
    api: hubletAwareApi
  });
}
export function fetchPublicAvailabilityInfo(httpClient, queryParams, useRetryClient = false) {
  if (useRetryClient) {
    return retryableGetClient({
      retryCount: 2,
      retryStatusCodes: PUBLIC_AVAILABILITY_INFO_FETCH_RETRY_STATUS_CODES,
      url: `${PUBLIC_BOOK_ENDPOINT}`,
      options: {
        withCredentials: false,
        query: queryParams,
        timeout: 12000,
        api: hubletAwareApi
      }
    });
  }
  return httpClient.get(`${PUBLIC_BOOK_ENDPOINT}`, {
    withCredentials: false,
    query: queryParams,
    timeout: 12000,
    api: hubletAwareApi
  });
}
export function fetchPublicAvailabilityInfoFallback(httpClient, queryParams) {
  return httpClient.get(`${FAILURE_ENDPOINT}`, {
    withCredentials: false,
    query: queryParams,
    timeout: 12000,
    api: hubletAwareApi
  });
}
export function fetchCompanyName(httpClient, slug) {
  return httpClient.get(`${LINK_ENDPOINT}/${slug}/company`, {
    withCredentials: false,
    api: hubletAwareApi
  });
}
export function createMeeting(httpClient, queryParams, data, useRetryClient = false) {
  if (useRetryClient) {
    return retryablePostClient({
      retryCount: 2,
      url: `${PUBLIC_BOOK_ENDPOINT}`,
      options: {
        withCredentials: false,
        api: hubletAwareApi,
        query: queryParams,
        timeout: 30000,
        data
      }
    });
  }
  const headers = triggerFailureInjectionViaHeaders(queryParams.slug || '');
  return httpClient.post(`${PUBLIC_BOOK_ENDPOINT}`, {
    withCredentials: false,
    api: hubletAwareApi,
    query: queryParams,
    timeout: 30000,
    data,
    headers
  });
}
export function meetingBookFailure(httpClient, queryParams, data) {
  return httpClient.post(`${FAILURE_ENDPOINT}`, {
    withCredentials: false,
    api: hubletAwareApi,
    query: queryParams,
    timeout: 30000,
    data
  });
}
export function fetchEventDetails(httpClient, slug, meetingChangeId) {
  return httpClient.get(`${PUBLIC_BOOK_ENDPOINT}/meeting`, {
    query: {
      meetingChangeId,
      slug
    }
  });
}
export function cancelMeeting(httpClient, slug, meetingChangeId) {
  return httpClient.delete(PUBLIC_BOOK_ENDPOINT, {
    withCredentials: false,
    api: hubletAwareApi,
    query: {
      slug,
      meetingChangeId
    },
    timeout: 30000
  });
}
export function rescheduleMeeting(httpClient, meetingRescheduleRequest) {
  return httpClient.post(`${PUBLIC_BOOK_ENDPOINT}/reschedule`, {
    withCredentials: false,
    api: hubletAwareApi,
    timeout: 30000,
    data: meetingRescheduleRequest
  });
}